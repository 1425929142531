import { Fragment } from "react";
import { Option, Select, Typography } from "@material-tailwind/react";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline";
import { DateRangePicker } from "rsuite";
import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  subYears,
} from "date-fns";
import { ReactNode } from "react";

interface filterProps {
  onHide: () => void;
  defaultDates: [Date, Date];
  handleDateChange: (value: [Date, Date]) => void;
  payMethod: string;
  onPayMethodChange: any;
  transType: string;
  onTransChange: any;
  onPaymentStatusChange: any;
  paymentStatus: any;
  showStatusFilter?: boolean;
}

interface Range {
  label: ReactNode;
  value: any;
  closeOverlay?: boolean;

  // Sets the position where the predefined range is displayed, the default is bottom.
  placement?: "bottom" | "left";
}

const PaymentsFilterPane = ({
  onHide,
  defaultDates,
  handleDateChange,
  payMethod,
  onPayMethodChange,
  transType,
  onTransChange,
  onPaymentStatusChange,
  paymentStatus,
  showStatusFilter = false,
}: filterProps) => {
  const customRanges: Range[] = [
    {
      label: "Today",
      value: [startOfDay(new Date()), endOfDay(new Date())],
    },
    {
      label: "Yesterday",
      value: [
        startOfDay(new Date(new Date().setDate(new Date().getDate() - 1))),
        endOfDay(new Date(new Date().setDate(new Date().getDate() - 1))),
      ],
    },
    {
      label: "This Week",
      value: [
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        endOfWeek(new Date(), { weekStartsOn: 1 }),
      ],
    },
    {
      label: "Last Week",
      value: [
        startOfWeek(new Date(new Date().setDate(new Date().getDate() - 7)), {
          weekStartsOn: 1,
        }),
        endOfWeek(new Date(new Date().setDate(new Date().getDate() - 7)), {
          weekStartsOn: 1,
        }),
      ],
    },
    {
      label: "This Month",
      value: [startOfMonth(new Date()), endOfMonth(new Date())],
    },
    {
      label: "Last Month",
      value: [
        startOfMonth(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1)
        ),
        endOfMonth(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1)
        ),
      ],
    },
    {
      label: "This Year",
      value: [startOfYear(new Date()), endOfYear(new Date())],
    },
    {
      label: "Last Year",
      value: [
        startOfYear(subYears(new Date(), 1)),
        endOfYear(subYears(new Date(), 1)),
      ],
    },
  ];

  return (
    <Fragment>
      <div className="px-3 py-[20px] bg-white border-l-[1px] border-gray-500 h-full overflow-y-auto">
        {/* header */}
        <div className="mb-2 flex justify-between items-center">
          <Typography variant="h5" color="blue-gray">
            Filter
          </Typography>
          <ChevronDoubleRightIcon
            className="w-5 h-5 cursor-pointer"
            onClick={() => onHide()}
          />
        </div>

        {/* content */}
        <div className="">
          {/* date picker */}
          <div className="mt-5 w-full">
            <p>Date Rage</p>
            <DateRangePicker
              className="w-full"
              placement="leftStart"
              value={defaultDates}
              onChange={(e: any) => handleDateChange(e)}
              ranges={customRanges}
            />
          </div>

          <div className="mt-5 w-full">
            <p className="mb-1">Filter by payment method</p>
            <Select
              label="Select method"
              value={payMethod}
              onChange={(value) => onPayMethodChange(value)}
            >
              <Option value="MOBILE_MONEY">Momo</Option>
              <Option value="CASH_GH">Cash</Option>
            </Select>
          </div>
          <div className="mt-5 w-full">
            <p className="mb-1">Filter by transaction type</p>
            <Select
              label="Select type"
              value={transType}
              onChange={(value) => onTransChange(value)}
            >
              <Option value="debit">Debit/Received</Option>
              <Option value="credit">Credit/Transfer</Option>
            </Select>
          </div>
          {showStatusFilter && (
            <div className="mt-5 w-full">
              <p className="mb-1">Filter by status</p>
              <Select
                label="Select method"
                value={paymentStatus}
                onChange={(value) => onPaymentStatusChange(value)}
              >
                <Option value="1">Pending</Option>
                <Option value="3">Paid</Option>
                <Option value="4">Failed</Option>
              </Select>
            </div>
          )}
          <div className="flex justify-end mt-5 lg:hidden">
            <button
              className="border-[1px] border-oya-ghana-red text-oya-ghana-red px-4 py-1 rounded-[5px] hover:bg-oya-ghana-red hover:text-white"
              onClick={() => onHide()}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PaymentsFilterPane;
